import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { LocalStoreService } from "../services/local-store.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private local: LocalStoreService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log(request)

    var token =  this.local.getItem('token');

    console.log ("=================================");
    console.log (token);
    console.log ("=================================");

    // SI token à insérer dans le header
    if(token !== null){
      let clone = request.clone({
        headers: request.headers.set('Authorization', 'Bearer '+token)
      })

      console.log ("*********************************");
      console.log(clone);
      console.log ("*********************************");
      return next.handle(clone)
    }
    
    
    return next.handle(request);
  }
}


export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}



/*

import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { LocalStoreService } from "../services/local-store.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(//private jwtAuth: JwtAuthService,
              private local: LocalStoreService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();

    var token =  this.local.getItem('token');

    console.log ("=================================");
    console.log (token);
    console.log ("=================================");

    var changedReq;

    if (token) {

      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
      });

    } else {

      changedReq = req;
      
    }
    return next.handle(changedReq);
  }
}


*/